<template>
  <app-btn
    color="blue"
    class="mb-3"
    outlined
    @click="restore">
    Restore default view
  </app-btn>
</template>
<script>
export default {
  name: 'ProjectDetailsViewsRestoreDefault',
  methods: {
    async restore() {
      const questionTitle = `
        Do you want to restore the default view?
        This will reset the fields visibility, order, and column widths to default settings.
        You CANNOT UNDO this action.`;
      let confirm = await this.$openConfirm({
        text: questionTitle,
      });
      if (!confirm) return;
      this.$emit('restore');
    },
  },
};
</script>